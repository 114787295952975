<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useConfirmComponent } from '/~/modals/confirm/confirm'

const props = defineProps<{
  title: string
  description: string
  onConfirm?: () => Promise<void>
  onCancel?: () => Promise<void>
}>()

const { confirming, cancelling, loading, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
  })

function hide() {
  modal.hide()
}
</script>
<template>
  <base-mdl
    width="xse"
    border-radius="2xl"
    layout="v2"
    :title="title"
    :closable="!loading"
  >
    <div>
      <div class="text-eonx-neutral-600">
        <p class="text-center">
          {{ description }}
        </p>
      </div>
      <div class="mt-8 flex space-x-2.5">
        <base-button
          v-analytics:click="{
            cta: 'Cancel',
            pageGroup: 'Confirm Modal',
            page: props.title,
          }"
          class="flex-auto"
          look="outlined-color"
          size="lge"
          :disabled="loading"
          :loading="cancelling"
          @click="onCancel"
        >
          Cancel
        </base-button>
        <base-button
          v-analytics:click="{
            cta: 'Confirm',
            pageGroup: 'Confirm Modal',
            page: props.title,
          }"
          class="flex-auto"
          size="lge"
          :disabled="loading"
          :loading="confirming"
          @click="onConfirm"
        >
          Confirm
        </base-button>
      </div>
    </div>
  </base-mdl>
</template>
